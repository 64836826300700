<template>
  <div class="page">
    <Sidebar/>
    <div class="main">
      <Navbar class="w-full z-50 center-x center-y"/>
      <div class="w-full flex justify-center">
        <section class="section">
          <div class="mt-8">
            <h1 class="font-sans font-bold text-black-base text-2xl mb-8">
              Company
            </h1>
            <SettingsTable class="w-full"/>
          </div>
        </section>
      </div>
    </div>
    <NavBottom/>
  </div>
</template>

<script>
import Navbar from "@/components/Layout/Navbar";
import SettingsTable from "@/components/Company/SettingsTable";
import Sidebar from "@/components/Layout/Sidebar/Sidebar";
import NavBottom from "@/components/Layout/NavBottom";

export default {
  name: "Company",
  components: {
    NavBottom,
    Navbar,
    SettingsTable,
    Sidebar
  }
}
</script>

<style scoped>
.page {
  @apply flex bg-grey-light h-100vh overflow-hidden;
  @apply pb-12 md:p-0;
}

.main {
  @apply self-start w-full relative h-full overflow-y-auto;
}

.section {
  @apply px-4 md:px-12 mt-5 pb-12 max-w-2xl w-full;
}
</style>