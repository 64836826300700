<template>
  <div>
    <BackgroundLayer v-if="showOverlay" class="bg-overlay" @click.native="closeDialog()"/>

    <div v-if="dialog.show" class="dialog-container">
      <Dialog :icon-class="dialog.iconClass" :text="dialog.text" :title="dialog.title"
              @approveDialog="submitDeleteProfilePhoto" @cancelDialog="closeDialog"/>
    </div>


    <Avatar :img="companyAvatar"/>
    <div v-if="showFileUpload" class="upload-container">
      <input ref="profilePhoto" type="file">
      <span>JPG, JPEG or PNG. Max size of 3MB</span>

      <div class="btn-container">
        <ButtonPrimary class="px-3 mr-3" text="Upload now" @click.native="submitUpdateCompanyDetails('profilePhoto')"/>
        <ButtonSecondary text="Cancel" @click.native="hideUploadField"/>
      </div>
    </div>
    <div v-if="!showFileUpload" class="btn-container">
      <ButtonPrimary class="px-3 mr-3" text="Upload new picture" @click.native="showUploadField"/>
      <ButtonSecondary text="Delete" @click.native="openDialog"/>
    </div>
    <div class="flex text-black-lighter mt-3">
      <i class="bx bx-info-circle bx-xs mr-1.5 mt-0.5"></i>
      <span class="text-xs font-light">It is recommended to upload the picture of an agent who will have contact with the travelers.</span>
    </div>
    <Divider class="mt-8"/>
    <div class="row-item">
      <div class="text-container">
        <div class="flex justify-between items-center">
          <h4 class="font-sans font-semibold text-black-base mb-1">Company name</h4>
          <button
              v-if="!showNameEdit"
              class="btn"
              type="button"
              @click="editName"
          >
            Edit
          </button>
          <button
              v-if="showNameEdit"
              class="btn"
              type="button"
              @click="cancelEditName"
          >
            Cancel
          </button>
        </div>
        <p v-if="!showNameEdit" class="font-sans text-black-lighter text-sm">
          {{ userDetails.companyName }}
        </p>
        <div>
          <InputText
              v-if="showNameEdit"
              v-model="userDetails.companyName"
              :showLabel="false"
              class="input-contained"
              name="name"
              placeholder="Your company name"
              type="text"
          />
          <ButtonPrimary v-if="showNameEdit" class="px-8 mt-4" text="Save"
                         @click.native="submitUpdateCompanyDetails('companyName')"/>
        </div>
      </div>
    </div>
    <div class="row-item">
      <div class="text-container">
        <div class="flex justify-between items-center">
          <h4 class="font-sans font-semibold text-black-base mb-1">Website</h4>
          <button
              v-if="!showWebsiteEdit"
              class="btn"
              type="button"
              @click="editWebsite"
          >
            Edit
          </button>
          <button
              v-if="showWebsiteEdit"
              class="btn"
              type="button"
              @click="cancelEditWebsite"
          >
            Cancel
          </button>
        </div>

        <p v-if="!showWebsiteEdit" class="font-sans text-black-lighter text-sm">
          {{ userDetails.website ? userDetails.website : 'No website provided' }}
        </p>
        <div>
          <InputText
              v-if="showWebsiteEdit"
              v-model="userDetails.website"
              :showLabel="false"
              class="w-full mt-2"
              name="phone-number"
              placeholder="e.g. www.example.com"
              type="text"
          />
          <ButtonPrimary v-if="showWebsiteEdit" class="px-8 mt-4" text="Save"
                         @click.native="submitUpdateCompanyDetails('website')"/>
        </div>
      </div>
    </div>
    <div class="row-item">
      <div class="text-container">
        <div class="flex justify-between items-center">
          <h4 class="font-sans font-semibold text-black-base mb-1">Address</h4>

          <button
              v-if="!showAddressEdit"
              class="btn"
              type="button"
              @click="editAddress"
          >
            Edit
          </button>
          <button
              v-if="showAddressEdit"
              class="btn"
              type="button"
              @click="cancelEditAddress"
          >
            Cancel
          </button>
        </div>

        <p v-if="!showAddressEdit" class="font-sans text-black-lighter text-sm text-ellipsis truncate overflow-hidden">
          {{ userDetails.companyAddress }}
        </p>
        <div>
          <InputText
              v-if="showAddressEdit"
              v-model="address.street"
              :showLabel="false"
              class="w-full mt-2"
              name="text"
              placeholder="Street & housenumber"
              type="text"
          />
          <div class="input-container">
            <InputText
                v-if="showAddressEdit"
                v-model="address.zip"
                :showLabel="false"
                class="input-contained"

                name="text"
                placeholder="ZIP Code"
                type="text"
            />
            <InputText
                v-if="showAddressEdit"
                v-model="address.location"
                :showLabel="false"
                class="input-contained"
                name="text"
                placeholder="Location"
                type="text"
            />
          </div>
          <SelectDropdown v-if="showAddressEdit" v-model="address.country"
                          :optionsList="$africanDestinations" class="mt-2" description="Select a country"/>
          <ButtonPrimary v-if="showAddressEdit" class="px-8 mt-4" text="Save"
                         @click.native="submitUpdateCompanyDetails('address')"/>
        </div>
      </div>
    </div>
    <div :class="['row-item']">

      <div class="text-container">
        <div class="flex justify-between items-center">
          <h4 class="font-sans font-semibold text-black-base mb-1">Company email</h4>
          <button
              v-if="!showMailEdit"
              class="btn"
              type="button"
              @click="editMail"
          >
            Edit
          </button>
          <button
              v-if="showMailEdit"
              class="btn"
              type="button"
              @click="cancelEditMail"
          >
            Cancel
          </button>
        </div>
        <p
            v-if="!showMailEdit"
            class="font-sans text-black-lighter text-sm text-ellipsis truncate overflow-hidden"
        >
          {{ userDetails.companyEmail }}
        </p>
        <div>
          <InputText
              v-if="showMailEdit"
              v-model="userDetails.companyEmail"
              :showLabel="false"
              class="w-full mt-2"
              name="email"
              placeholder="e.g. info@example.com"
              type="email"
          />
          <ButtonPrimary
              v-if="showMailEdit"
              class="px-8 mt-4"
              text="Save"
              @click.native="submitUpdateCompanyDetails('companyEmail')"
          />
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
import InputText from "@/components/Inputs/InputText";
import {mapActions, mapGetters} from "vuex";
import SelectDropdown from "@/components/Inputs/SelectDropdown";
import ButtonSecondary from "@/components/Buttons/ButtonSecondary";
import Divider from "@/components/Layout/Divider";
import Avatar from "@/components/Avatar";
import Dialog from "@/components/Dialogs/Dialog";
import BackgroundLayer from "@/components/Layout/BackgroundLayer";

export default {
  name: "SettingsTable",
  data() {
    return {
      showFileUpload: false,
      showMailEdit: false,
      showPasswordEdit: false,
      showNameEdit: false,
      showAddressEdit: false,
      showWebsiteEdit: false,
      userDetails: {},
      address: {
        street: "",
        zip: "",
        location: "",
        country: ""
      },
    };
  },
  components: {
    Avatar,
    Divider,
    ButtonSecondary,
    ButtonPrimary,
    InputText,
    SelectDropdown,
    Dialog,
    BackgroundLayer
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      dialog: "dialog/dialog",
      showOverlay: "dialog/showOverlay",
    }),
    companyAvatar() {
      if (this.userDetails.profilePhoto)
        return `${this.basePath}${this.userDetails.profilePhoto}`;

      return require("@/assets/img/placeholders/placeholder.png");
    },
    basePath() {
      return process.env.VUE_APP_FILE_BASE_PATH;
    },
  },

  props: {
    props: {
      style: String,
      default: "opacity-5",
    },
    email: {
      type: String,
      default: "example@travelafrique.com",
    },
  },

  mounted() {
    this.getCompanyDetails().then(({data}) => {
      this.userDetails = {...data.company};
    })
  },


  methods: {
    ...mapActions({
      displayDialog: "dialog/displayDialog",
      displayOverlay: "dialog/displayOverlay",
      setDialogTitle: "dialog/setDialogTitle",
      setDialogText: "dialog/setDialogText",
      setDialogIcon: "dialog/setDialogIcon",
      setDialogIconColor: "dialog/setDialogIconColor",
      getCompanyDetails: "auth/getCompanyDetails",
      updateCompanyDetails: "auth/updateCompanyDetails",
      deleteProfilePhoto: "auth/deleteProfilePhoto",
      hideOverlay: "dialog/hideOverlay",
      hideDialog: "dialog/hideDialog"
    }),
    openDialog() {
      this.displayOverlay();
      this.displayDialog();
      this.setDialogTitle("Delete Photo");
      this.setDialogText("Are you sure you want to delete this photo?");
      this.setDialogIcon("bx bxs-error-circle bx-sm");
    },
    closeDialog() {
      this.hideOverlay();
      this.hideDialog();
    },
    showUploadField() {
      this.showFileUpload = true;
    },
    hideUploadField() {
      this.showFileUpload = false;
    },
    editMail() {
      this.showMailEdit = true;
    },
    cancelEditMail() {
      this.showMailEdit = false;
    },
    editPassword() {
      this.showPasswordEdit = true;
    },
    cancelEditPassword() {
      this.showPasswordEdit = false;
    },
    editName() {
      this.showNameEdit = true;
    },
    cancelEditName() {
      this.showNameEdit = false;
    },
    editAddress() {
      this.showAddressEdit = true;
    },
    cancelEditAddress() {
      this.showAddressEdit = false;
    },
    editWebsite() {
      this.showWebsiteEdit = true;
    },
    cancelEditWebsite() {
      this.showWebsiteEdit = false;
    },

    submitUpdateCompanyDetails(type) {
      let formData = new FormData();

      if (type === 'companyName') {
        formData.append('companyName', this.userDetails.companyName)
      }

      if (type === "website") {
        formData.append('website', this.userDetails.website)
      }

      if (type === "companyEmail") {
        formData.append('companyEmail', this.userDetails.companyEmail)
      }

      if (type === "companyZipCode") {
        formData.append('companyZipCode', this.userDetails.companyZipCode)
      }

      if (type === "companyEmail") {
        formData.append('companyEmail', this.userDetails.companyEmail)
      }

      if (type === "address") {
        let fullAddress = this.address.street + ', ' + this.address.location + ', ' + this.address.country + ', ' + this.address.zip
        formData.append('companyAddress', fullAddress)
        formData.append('companyZipCode', this.userDetails.companyZipCode)
      }
      if (type === 'profilePhoto') {
        let fileInputFiles = this.$refs.profilePhoto.files;
        formData.append('profilePhoto', fileInputFiles[0])
      }


      this.updateCompanyDetails(formData).then(({data}) => {
        this.userDetails = {...data.partner};
        this.showFileUpload = false;
        this.showMailEdit = false;
        this.showPasswordEdit = false;
        this.showNameEdit = false;
        this.showAddressEdit = false;
        this.showWebsiteEdit = false;
        this.$notify({
          type: "success",
          title: `Successful`,
          text: data.message,
        });
      }).catch(e => {
        this.$notify({
          type: "error",
          title: `Failed`,
          text: e.message,
        });
      })
    },

    submitDeleteProfilePhoto() {
      this.deleteProfilePhoto().then(({data}) => {
        this.userDetails = {...data.partner};
        this.$notify({
          type: "success",
          title: `Successful`,
          text: data.message,

        });
      }).catch(e => {
        this.$notify({
          type: "error",
          title: `Failed`,
          text: e.message,
        });
      })
      this.closeDialog();
    }
  },
};
</script>
<style scoped>
.row-item {
  @apply w-full border-b border-grey-base py-8;
}

.btn {
  @apply font-sans text-teal-base focus:outline-none duration-300 transition hover:underline;
}

.input-container {
  @apply flex justify-between mt-4;
}

.input-contained {
  @apply w-full w-calc-1/2;
}

.btn-container {
  @apply mt-4;
}

.upload-container {
  @apply mt-4;
}

.upload-container > span {
  @apply block text-xs mt-2 text-black-lightest;
}

.dialog-container {
  @apply fixed z-50 left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2;
  @apply w-full px-3;
  @apply flex justify-center;
}

.bg-overlay {
  @apply fixed z-40 left-0 top-0;
}

</style>