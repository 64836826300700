<template>
  <figure class="avatar">
    <img :src="img"
         :alt="alt">
  </figure>
</template>

<script>
export default {
  name: "Avatar",
  props: {
    img: {
      type: String,
      default: require("@/assets/img/placeholders/placeholder.png"),
    },
    alt:{
      type:String,
      default:"Placeholder image",
    }
  },
  computed:{
    basePath() {
      return process.env.VUE_APP_FILE_BASE_PATH;
    },
  }
}
</script>

<style scoped>
.avatar {
  @apply h-24 w-24 rounded-full overflow-hidden;
  @apply border border-grey-dark;
  @apply relative;
}

.avatar img {
  @apply w-full h-full;
  @apply object-cover absolute top-0 left-0;
}
</style>